export const getGradeName = (id) => {
        if (id === 1) {
            return "小一";
        } else if (id === 2) {
            return "小二";
        } else if (id === 3) {
            return "小三";
        } else if (id === 4) {
            return "小四";
        } else if (id === 5) {
            return "小五";
        } else if (id === 6) {
            return "小六";
        } else if (id === 7) {
            return "初一";
        } else if (id === 8) {
            return "初二";
        } else if (id === 9) {
            return "初三";
        }else if (id === 10) {
            return "高一";
        } else if (id === 11) {
            return "高二";
        } else if (id === 12) {
            return "高三";
        } 
        
        else {
            return ""; 
        }

};
export const getType = (a) => {

    if (1.57 > a && a >= 0.66) {
        return "正常"
      }
      if (0.66 > a && a >= 0.5) {
        return "轻度扁平"
      }
      if (2 > a && a >= 1.57) {
        return "轻度高弓"
      }
  
      if (0.5 > a && a >= 0.33) {
        return "中度扁平"
      }
      if (3 > a && a >= 2) {
        return "中度高弓"
      }
      if (0.33 > a && a >= 0) {
        return " 重度扁平"
      }
      if (10 >= a && a >= 3) {
        return " 重度高弓"
      }
      return  a
};
export const isDifferenceGreaterThan40=(num1, num2)=> {
    if (Math.abs(num1 - num2) > 10) {
      return true
    } else {
      return false
    }
  };
